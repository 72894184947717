import React from 'react';
import { Flex, Text, Spacer, Button, Table, Tbody, Tr, Td, Box } from '@chakra-ui/react';
import Locale from '../../utils/translations';
import { BonfireAPI, ApiType } from '../../models/BonfireApi';
import useTranslation from '../../components/hooks/useTranslations';

const RedactionListItem = ({ redaction }: any) => {
	const feedbackOptions = [
		{ value: 'Sensitive information is fully redacted.' },
		{ value: 'Sensitive information is partially redacted.' },
		{
			value: 'No sensitive information is redacted.',
		},
	];
	const [selectedFeedback, setSelectedFeedback] = React.useState<string>(redaction.answer);
	const handleFeedbackChange = (value: string) => {
		setSelectedFeedback(value);
		try {
			const api = new BonfireAPI(ApiType.QaApi);
			api.patch('/qa/updateAnswer', { action: value, id: redaction._id });
		} catch (error) {
			console.error(error);
		}
	};
	return (
		<Tr bg="white" borderRadius="4px" fontWeight="600" fontSize="14px" display={'block'} mt={3}>
			<Tr>
				<Td borderRight="none" p="8px 16px" borderStartRadius="4px" />

				<Td gridTemplateColumns="auto 1fr">
					<Box mb={5}>
						<Text variant="beTextDescription">Original:</Text>
						<Text variant="beTextDescription" fontWeight={700} fontSize={18}>
							{redaction.input.text}
						</Text>
					</Box>
					<Box>
						<Text variant="beTextDescription" color="#3182ce">
							Redacted:
						</Text>
						<Text
							variant="beTextDescription"
							color="#3182ce"
							fontWeight={700}
							fontSize={18}
						>
							{redaction.output.text}
						</Text>
					</Box>
				</Td>
			</Tr>
			<Tr bg="white" borderRadius="4px" fontWeight="600" fontSize="14px">
				<Td borderRight="none" p="8px 16px" borderStartRadius="4px" />
				<Td>
					<Text variant="beTextDescription" alignContent="center" pb={2}>
						{Locale.get('Feedback on redaction quality')}
					</Text>
					<Box display="flex" flexWrap="wrap" justifyContent="center" gap="8px">
						{feedbackOptions.map((option, index) => (
							<Button
								key={index}
								onClick={() => handleFeedbackChange(option.value)}
								bg={selectedFeedback === option.value ? 'blue.500' : 'white'}
								color={selectedFeedback === option.value ? 'white' : 'black'}
								_hover={{
									bg: selectedFeedback === option.value ? 'blue.600' : 'gray.100',
								}}
								border="1px solid"
								borderColor="#000"
								borderRadius="4px"
								px={4}
								py={2}
								fontSize="14px"
								fontWeight="700"
							>
								{option.value}
							</Button>
						))}
					</Box>
				</Td>
			</Tr>
		</Tr>
	);
};

const Redaction: React.FC = () => {
	const { translations, translationsLoading, moveNext, movePrev, offset, pageSize } =
		useTranslation('redaction');
	if (translationsLoading) {
		return <div>loading ...</div>;
	}
	return (
		<Box display={'flex'} flexWrap={'wrap'} justifyContent={'center'}>
			<Flex alignItems="center" justifyContent="space-between" w={'60%'}>
				<Text fontSize="18px" fontWeight="700">
					{Locale.get('Redaction')}
				</Text>
				<Spacer />
				<Button
					isDisabled={offset === 0}
					variant="beSecondaryButton"
					size="sm"
					mr={5}
					onClick={movePrev}
				>
					previous
				</Button>
				<Text variant="beTextDescription">
					{offset + 1} .. {offset + pageSize}
				</Text>
				<Button
					isDisabled={(translations ?? []).length !== 20}
					variant="bePrimaryButton"
					size="sm"
					ml={5}
					onClick={moveNext}
				>
					next
				</Button>
			</Flex>
			<Table
				mt="23px"
				variant="unstyled"
				style={{ borderCollapse: 'separate', borderSpacing: '0 0.5em' }}
				w={'60%'}
			>
				<Tbody>
					{translations?.map((redaction: any, index: any) => (
						<RedactionListItem redaction={redaction} key={index} />
					))}
				</Tbody>
			</Table>
		</Box>
	);
};

export default Redaction;
