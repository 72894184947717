import React from 'react';
import { Flex, Text, Button, Alert, AlertIcon } from '@chakra-ui/react';
import { SessionModel } from '../../../utils/interfaces';

const SessionCard: React.FC<{
	session: SessionModel;
	renderSessionChunks: (string: string) => void;
	renderSession: (string: string) => void;
	renderSessionSimulations: (string: string) => void;
	isExternal: boolean;
	isSessionLocked: boolean;
}> = ({
	session,
	renderSessionChunks,
	renderSession,
	renderSessionSimulations,
	isExternal,
	isSessionLocked,
}) => {
	return (
		<Flex
			key={session.sessionId}
			backgroundColor={'#fff'}
			borderWidth={1}
			borderColor={'#ccc'}
			width={'100%'}
			flexDirection={'row'}
			alignItems={'flex-start'}
			justifyContent={'space-between'}
			mb={4}
			p={5}
			borderRadius="md"
		>
			<Flex flex={1} flexDirection={'column'} ml={5} justifyContent="flex-start">
				{isSessionLocked && (
					<Alert status="warning" width="fit-content" display="inline-flex" py="1">
						<AlertIcon />
						<Text fontWeight={'bold'}>Session is being processed by another user.</Text>
					</Alert>
				)}

				<Text fontSize={18} fontWeight={'bold'}>
					{session.sessionId} ({session.client})
				</Text>
				{isExternal && (
					<>
						<Flex alignItems={'center'} justifyContent={'flex-start'} mt={3}>
							<Text mr={3} fontWeight={'bold'}>
								Length of call:{' '}
							</Text>
							{
								<Text mr={3}>
									{Math.floor(
										(new Date(session.dateAdded).getTime() -
											new Date(session.lastChunkDateAdded).getTime()) /
											60000,
									)}{' '}
									minutes and{' '}
									{Math.floor(
										((new Date(session.dateAdded).getTime() -
											new Date(session.lastChunkDateAdded).getTime()) %
											60000) /
											1000,
									)}{' '}
									seconds
								</Text>
							}
						</Flex>
						<Flex alignItems={'center'} justifyContent={'flex-start'}>
							<Text mr={3} fontWeight={'bold'}>
								Call started at:{' '}
							</Text>
							<Text>{new Date(session.lastChunkDateAdded).toLocaleString()}</Text>
						</Flex>
						<Flex alignItems={'center'} justifyContent={'flex-start'}>
							<Text mr={3} fontWeight={'bold'}>
								Languages used:{' '}
							</Text>
							{session.distinctLanguages &&
								session.distinctLanguages.map((language: string, index: number) => (
									<Flex
										key={index}
										backgroundColor={'#3182ce'}
										p={0.9}
										mr={1}
										color={'#fff'}
										width={'30px'}
										fontWeight={'bold'}
										alignItems={'center'}
										justifyContent={'center'}
										borderRadius="md"
									>
										{language}
									</Flex>
								))}
						</Flex>
					</>
				)}
			</Flex>
			<Flex alignItems="center" justifyContent="flex-start" mx={5}>
				<Button
					isDisabled={isSessionLocked}
					onClick={() => {
						renderSessionChunks(session.sessionId);
					}}
					mx={2}
				>
					{isExternal ? 'Verify' : 'Chunks'}
				</Button>
				{!isExternal && (
					<>
						<Button
							isDisabled={isSessionLocked}
							mx={2}
							onClick={() => {
								renderSession(session.sessionId);
							}}
						>
							Visualization
						</Button>
						<Button
							isDisabled={isSessionLocked}
							onClick={() => {
								renderSessionSimulations(session.sessionId);
							}}
							mx={2}
						>
							Simulations
						</Button>
					</>
				)}
			</Flex>
		</Flex>
	);
};

export default SessionCard;
