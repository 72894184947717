import React from 'react';
import { List, ListItem, Box, Text } from '@chakra-ui/react';
import { Link, useMatch } from 'react-router-dom';

export default function StatisticsNavigation({ expandedNavBar }: { expandedNavBar: boolean }) {
	const werEvaluationSelected = useMatch('/statistics/wer-evaluation');

	return (
		<List position="relative" display={expandedNavBar ? 'block' : 'none'} marginTop="10px">
			<ListItem height="2em">
				<Box
					borderBottom="1px solid #D5DDE4"
					width="15px"
					borderBottomLeftRadius="6px"
					position="absolute"
					height="1em"
					borderLeft="1px solid #D5DDE4"
				/>
				<Link
					to="/statistics/wer-evaluation"
					aria-current={werEvaluationSelected ? 'page' : undefined}
				>
					<Text
						_hover={{ background: '#F0F3F6' }}
						marginLeft="20px"
						padding="5px"
						fontSize="14px"
						borderRadius="9px"
						fontWeight={500}
						cursor="pointer"
						background={werEvaluationSelected ? '#F0F3F6' : 'none'}
						textDecoration={werEvaluationSelected ? 'underline' : undefined}
					>
						WER evaluation
					</Text>
				</Link>
			</ListItem>
		</List>
	);
}
