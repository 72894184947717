import useSWR from 'swr';
import { GET_USER } from '../../models/users/urls';
import BonfireApi from '../../models/BonfireApi';
import UserModel from '../../models/users/users';
import { AxiosError } from 'axios';
import config from '../../utils/config';

const fetcher = async () => {
	const userResponse = await BonfireApi.request(GET_USER, UserModel);
	if (userResponse instanceof AxiosError) {
		throw userResponse;
	}

	return userResponse;
};

const isUserExternal = (user: UserModel) => {
	return !user.isAdmin;
};

export default function useUser() {
	const { data, error, isLoading, mutate } = useSWR(GET_USER.path, fetcher, {
		refreshInterval: 5000,
	});

	if (error?.response?.status === 404) {
		const portalUrl = window.location.origin;

		const redirectData = { successRedirect: `${portalUrl}/` };
		const loginData = btoa(JSON.stringify(redirectData));
		window.location.href = `${config.login.url}/login?t=${loginData}`;
	}

	const isExternal = data ? isUserExternal(data as UserModel) : false;

	return {
		userData: data as UserModel,
		isExternal,
		userError: error,
		userIsLoading: isLoading,
		mutateUserData: mutate,
	};
}
