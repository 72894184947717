import React, { useEffect } from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';
import { BonfireAPI, ApiType } from '../../../models/BonfireApi';
import { useParams } from 'react-router-dom';
import 'react-resizable/css/styles.css';
import config from '../../../utils/config';
import CreateSimulationModal from './CreateSimulationModal';
import AddGroundTruthModal from './AddGroundTruthModal';

const RenderSessionSimulations: React.FC = () => {
	const { sessionId } = useParams<{ sessionId: string }>();
	const [originalSession, setOriginalSession] = React.useState<any>();
	const [simulations, setSimulations] = React.useState<any>([]);
	const [haveRecording, setHaveRecording] = React.useState<boolean>(false);
	const buffer = React.useRef<any>();
	const reloadInterval = React.useRef<any>();
	const [modalOpen, setModalOpen] = React.useState<boolean>(false);
	const [GTModalOpen, setGTModalOpen] = React.useState<boolean>(false);
	const [gtSessionSelected, setGtSessionSelected] = React.useState<any>();

	useEffect(() => {
		startReloadDataTimer();
		return () => {
			clearInterval(reloadInterval.current);
		};
	}, []);

	useEffect(() => {
		if (modalOpen) {
			setModalOpen(false);
		}
	}, [modalOpen]);

	useEffect(() => {
		if (GTModalOpen) {
			setGTModalOpen(false);
		}
	}, [GTModalOpen]);

	const loadData = async () => {
		try {
			const api = new BonfireAPI(ApiType.QaApi);
			const simulationsRemoteData = await api.post('simulations/list', {
				sessionId,
			});
			simulationsRemoteData.map((simulation: any) => {
				if (simulation.parentSessionId === simulation.sessionId) {
					setOriginalSession(simulation);
				}
			});
			setSimulations(simulationsRemoteData);
		} catch (error) {
			console.error(error);
		}
	};

	const startReloadDataTimer = () => {
		reloadInterval.current = setInterval(() => {
			loadData();
		}, 3000);
	};

	const fetchAudioFile = async () => {
		if (!sessionId) {
			return;
		}
		if (buffer.current) {
			return;
		}

		let response;

		try {
			const url = `${config.qa_api.url}/files/getFile?type=recordings&id=${sessionId}`;
			response = await fetch(url, {
				method: 'GET',
				credentials: 'include',
			});
			if (response.status !== 200) {
				return;
			}
			setHaveRecording(true);
		} catch (error) {
			return;
		}
	};

	const startSimulation = async (simulationSessionId: string) => {
		const url = `${config.qa_api.url}/simulations/start`;
		const api = new BonfireAPI(ApiType.QaApi);
		await api.post(url, { sessionId: simulationSessionId });
		await loadData();
	};

	const startCalculation = async (simulationSessionId: string) => {
		const url = `${config.qa_api.url}/simulations/calculate`;
		const api = new BonfireAPI(ApiType.QaApi);
		await api.post(url, { sessionId: simulationSessionId });
		await loadData();
	};

	useEffect(() => {
		loadData();
		fetchAudioFile();
	}, []);

	const findStat = (simulatedSession: any, type: string, language: string, key: string) => {
		const stats = simulatedSession.stats.find((stat: any) => stat.key === language);
		if (!stats) {
			return 0;
		}
		if (type === 'fragmentStats') {
			return stats.value.fragmentStats[key];
		} else if (type === 'translationsQuality') {
			return Math.floor(stats.value.quality[key] * 100) / 100 || 0;
		} else if (type === 'translationLatency') {
			return (stats.value.translationLatency && stats.value.translationLatency[key]) || 0;
		} else if (type === 'translationProviderLatency') {
			return (
				(stats.value.translationProviderLatency &&
					stats.value.translationProviderLatency[key]) ||
				0
			);
		}
		return 0;
	};

	const renderStat = (simulatedSession: any, type: string, isFirst: boolean = false) => {
		let title = '';

		if (type === 'fragmentStats') {
			title = 'Fragment length';
		} else if (type === 'translationsQuality') {
			title = 'Translations quality';
		} else if (type === 'translationLatency') {
			title = 'Translations latency';
		} else if (type === 'translationProviderLatency') {
			title = 'Translations provider latency';
		}
		const languages = simulatedSession.stats.map((stat: any) => stat.key);

		if (languages.length === 0) {
			return null;
		}

		return (
			<Flex flexDirection={'column'} flex={1}>
				<Flex
					alignItems={'center'}
					justifyContent={'center'}
					backgroundColor="#adedf4"
					p={5}
					borderWidth={1}
					borderColor={'#CCC'}
				>
					<Text mr={3} fontSize={16} fontWeight={'bold'}>
						{title}
					</Text>
				</Flex>
				<Flex flexDirection="column">
					<Flex flexDirection="row" backgroundColor="#0b9cbb">
						{isFirst && <Flex width={100} />}
						{languages.map((language: string) => (
							<Flex
								alignItems="center"
								justifyContent={'center'}
								flex={1}
								borderWidth={1}
								borderColor={'#CCC'}
								key={language}
							>
								{language.toUpperCase()}
							</Flex>
						))}
					</Flex>
					<Flex alignItems={'center'} justifyContent={'center'} backgroundColor="#eee">
						<Flex flexDirection="column" borderWidth={1} borderColor={'#CCC'} flex={1}>
							<Flex flex={1}>
								{isFirst && (
									<Flex
										width={100}
										justifyContent={'center'}
										alignItems={'center'}
										backgroundColor="#0b9cbb"
										borderWidth={1}
										borderColor={'#CCC'}
									>
										Min
									</Flex>
								)}
								<Flex flex={1}>
									{languages.map((language: any) => (
										<Flex
											flex={1}
											justifyContent={'center'}
											alignItems={'center'}
											borderWidth={1}
											borderColor={'#CCC'}
											key={'min_' + language}
										>
											<Text fontSize={16}>
												{findStat(simulatedSession, type, language, 'min')}
											</Text>
										</Flex>
									))}
								</Flex>
							</Flex>
							<Flex flex={1}>
								{isFirst && (
									<Flex
										width={100}
										justifyContent={'center'}
										alignItems={'center'}
										backgroundColor="#0b9cbb"
										borderWidth={1}
										borderColor={'#CCC'}
									>
										Max
									</Flex>
								)}
								<Flex flex={1}>
									{languages.map((language: any) => (
										<Flex
											flex={1}
											justifyContent={'center'}
											alignItems={'center'}
											borderWidth={1}
											borderColor={'#CCC'}
											key={'max_' + language}
										>
											<Text fontSize={16}>
												{findStat(simulatedSession, type, language, 'max')}
											</Text>
										</Flex>
									))}
								</Flex>
							</Flex>
							<Flex flex={1}>
								{isFirst && (
									<Flex
										width={100}
										justifyContent={'center'}
										alignItems={'center'}
										backgroundColor="#0b9cbb"
										borderWidth={1}
										borderColor={'#CCC'}
									>
										Avg
									</Flex>
								)}
								<Flex flex={1}>
									{languages.map((language: any) => (
										<Flex
											flex={1}
											justifyContent={'center'}
											alignItems={'center'}
											borderWidth={1}
											borderColor={'#CCC'}
											key={'avg_' + language}
										>
											<Text fontSize={16}>
												{findStat(simulatedSession, type, language, 'avg')}
											</Text>
										</Flex>
									))}
								</Flex>
							</Flex>
						</Flex>
					</Flex>
				</Flex>
			</Flex>
		);
	};

	const openNewSimulationModal = () => {
		setModalOpen(true);
	};

	const showGroundTruthModal = (simulationSessionId: string) => {
		setGTModalOpen(true);
		setGtSessionSelected(simulationSessionId);
	};

	const renderGT = (simulation: any) => {
		if (!simulation.groundTruthWer && simulation.groundTruthWer !== 0) {
			return null;
		}

		return (
			<Flex alignItems={'center'} justifyContent={'flex-start'} mb={5}>
				<Text mr={3} fontWeight={'bold'}>
					Transcription ground truth WER:{' '}
				</Text>
				<Text mr={3}>{simulation.groundTruthWer}</Text>
			</Flex>
		);
	};

	return (
		<Flex flexDirection={'column'}>
			{simulations.length > 0 &&
				simulations.map((simulation: any) => {
					const isParent = simulation.sessionId === simulation.parentSessionId;
					return (
						<Flex
							flexDirection={'column'}
							borderWidth={1}
							p={5}
							key={simulation.sessionId}
							mb={5}
							backgroundColor={isParent ? '#83c4bf55' : '#fff'}
						>
							{isParent && (
								<Button onClick={openNewSimulationModal} alignSelf={'flex-end'}>
									+ Add new simulation
								</Button>
							)}

							{simulation.status === 'not_started' && (
								<Button
									onClick={() => {
										startSimulation(simulation.sessionId);
									}}
									alignSelf={'flex-end'}
								>
									Start
								</Button>
							)}
							{!['not_started', 'in_progress'].includes(simulation.status) && (
								<Button
									mt={5}
									onClick={() => {
										showGroundTruthModal(simulation.sessionId);
									}}
									alignSelf={'flex-end'}
								>
									Add ground truth
								</Button>
							)}
							{simulation.status === 'completed' && (
								<Button
									mt={5}
									onClick={() => {
										startCalculation(simulation.sessionId);
									}}
									alignSelf={'flex-end'}
								>
									Calculate
								</Button>
							)}

							<Flex alignItems={'center'} justifyContent={'flex-start'} mb={5}>
								<Text mr={3} fontWeight={'bold'}>
									Session name:{' '}
								</Text>
								<Text mr={3}>{simulation.name}</Text>
							</Flex>
							<Flex alignItems={'center'} justifyContent={'flex-start'} mb={5}>
								<Text mr={3} fontWeight={'bold'}>
									Session env:{' '}
								</Text>
								<Text mr={3}>{simulation.aiCoreUrl}</Text>
							</Flex>
							<Flex alignItems={'center'} justifyContent={'flex-start'} mb={5}>
								<Text mr={3} fontWeight={'bold'}>
									Session Id:{' '}
								</Text>
								<Text mr={3}>{simulation.sessionId}</Text>
							</Flex>
							<Flex alignItems={'center'} justifyContent={'flex-start'} mb={5}>
								<Text mr={3} fontWeight={'bold'}>
									Status:{' '}
								</Text>
								<Text mr={3}>{simulation.status}</Text>
							</Flex>
							{renderGT(simulation)}
							{isParent && haveRecording && (
								<Flex alignItems={'center'} justifyContent={'flex-start'} mb={5}>
									<Text mr={3} fontWeight={'bold'}>
										Recording:
									</Text>
									<audio controls>
										<source
											src={`${config.qa_api.url}/files/getFile?type=recordings&id=${sessionId}`}
											type="audio/wav"
										/>
										Your browser does not support the audio element.
									</audio>
								</Flex>
							)}

							{simulation.status === 'calculated' && (
								<Flex flexDirection={'row'}>
									{renderStat(simulation, 'translationsQuality', true)}
									{renderStat(simulation, 'fragmentStats')}
									{renderStat(simulation, 'translationLatency')}
									{renderStat(simulation, 'translationProviderLatency')}
								</Flex>
							)}
						</Flex>
					);
				})}
			<CreateSimulationModal
				doOpen={modalOpen}
				originalSession={originalSession}
				loadData={async () => {
					await loadData();
				}}
			/>
			<AddGroundTruthModal doOpen={GTModalOpen} session={gtSessionSelected} />
		</Flex>
	);
};

export default RenderSessionSimulations;
