import React from 'react';
import {
	Flex,
	Text,
	Spacer,
	Table,
	Tbody,
	Tr,
	Td,
	Box,
	Checkbox,
	Input,
	Button,
} from '@chakra-ui/react';
import { BonfireAPI, ApiType } from '../../../models/BonfireApi';

interface evaluationObject {
	_id: string;
	sessionId: string;
	text: string;
	chunkFeedback: {
		correctText: string;
		wer: number;
		adminHasCheckedWer: boolean;
	};
}

const EvaluationListItem = ({
	evalObject,
	onCheckboxChange,
	isChecked,
}: {
	evalObject: evaluationObject;
	onCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	isChecked: boolean;
}) => {
	return (
		<Box
			bg="white"
			borderRadius="1px"
			fontWeight="600"
			fontSize="14px"
			p="10px"
			w="100%"
			m={'10px'}
		>
			<Flex flexDirection={'row'} justifyContent={'space-between'}>
				<Flex flexDirection={'column'}>
					<Flex flexDirection={'row'} alignItems="flex-start" mb={2}>
						<Text
							variant="beTextDescription"
							fontWeight="bold"
							color="black"
							minWidth="120px"
						>
							Original Text:
						</Text>
						<Text variant="beTextDescription" ml="5px">
							{evalObject.text}
						</Text>
					</Flex>
					<Flex flexDirection={'row'} alignItems="flex-start" mb={2}>
						<Text
							variant="beTextDescription"
							fontWeight="bold"
							color="black"
							minWidth="120px"
						>
							Verified Text:
						</Text>
						<Text variant="beTextDescription" ml="5px">
							{evalObject.chunkFeedback.correctText}
						</Text>
					</Flex>
					<Flex flexDirection={'row'} alignItems="flex-start" mb={2}>
						<Text
							variant="beTextDescription"
							fontWeight="bold"
							color="black"
							minWidth="120px"
						>
							Word error rate:
						</Text>
						<Text variant="beTextDescription" ml="5px">
							{evalObject.chunkFeedback.wer}
						</Text>
					</Flex>
				</Flex>
				<Flex justifyContent="flex-end" alignItems="center" width={'100px'} m={5}>
					Is this wrongly evaluated?
					<Checkbox
						ml={1}
						isChecked={isChecked}
						onChange={onCheckboxChange} // Trigger passed function on change
					/>
				</Flex>
			</Flex>
		</Box>
	);
};

const WerEvaluation: React.FC = () => {
	const [werEvaluation, setWerEvaluation] = React.useState<evaluationObject[]>([]);
	const [werThreshold, setWerThreshold] = React.useState<string>('0.15');
	const [evaluatedChunks, setEvaluatedChunks] = React.useState<boolean>(false);

	const loadStats = async () => {
		try {
			const api = new BonfireAPI(ApiType.QaApi);
			const data = await api.get(
				`s2sflow/getEvaluatedChunks?showEvaluated=${evaluatedChunks}${
					werThreshold ? `&werThreshold=${werThreshold}` : ''
				}`,
			);
			setWerEvaluation(data);
		} catch (error) {
			console.error(error);
		}
	};

	React.useEffect(() => {
		loadStats();
	}, [evaluatedChunks]);

	const handleCheckboxChange = async (stat: evaluationObject, isChecked: boolean) => {
		const updatedStats = werEvaluation.map(s =>
			s._id === stat._id
				? { ...s, chunkFeedback: { ...s.chunkFeedback, adminHasCheckedWer: isChecked } }
				: s,
		);
		setWerEvaluation(updatedStats);

		const sessionId = stat.sessionId;
		const chunkId = stat._id;

		try {
			const api = new BonfireAPI(ApiType.QaApi);
			await api.post(
				`s2sflow/saveChunkFeedback?showEvaluated=${evaluatedChunks}&werThreshold=${werThreshold}`,
				{
					sessionId,
					chunkId,
					feedback: { ...stat.chunkFeedback, adminHasCheckedWer: isChecked },
				},
			);
		} catch (error) {
			console.error('Error updating admin checked status:', error);
		}
	};

	return (
		<Box display={'flex'} flexWrap={'wrap'} justifyContent={'center'}>
			<Flex alignItems="center" justifyContent="space-between" w={'60%'}>
				<Text fontSize="18px" fontWeight="700">
					WER evaluation
				</Text>
				<Spacer />
				<Flex ml={5} alignItems={'center'} justifyContent={'center'} flexDirection={'row'}>
					<Input
						type="number"
						pr={2}
						width={'30%'}
						shadow="0px 4px 4px 0px rgba(74, 74, 74, 0.04)"
						fontWeight="400"
						variant={'beVariant'}
						placeholder={'WER Threshold'}
						_placeholder={{ fontSize: '12px' }}
						value={werThreshold}
						onChange={e => setWerThreshold(e.target.value)}
					/>
					<Button ml={2} size="sm" variant={'bePrimary'} onClick={loadStats}>
						Filter
					</Button>
					<Text fontSize="14px" fontWeight="500" ml={5} mr={2}>
						Show evaluated
					</Text>
					<Checkbox
						ml={2}
						iconColor="white"
						onChange={() => setEvaluatedChunks(!evaluatedChunks)}
					/>
				</Flex>
			</Flex>
			<Table
				mt="23px"
				variant="unstyled"
				style={{ borderCollapse: 'separate', borderSpacing: '0 0.5em' }}
				w={'60%'}
			>
				<Tbody>
					{werEvaluation.length === 0 && (
						<Tr>
							<Td>No chunks to evaluate!</Td>
						</Tr>
					)}
					{werEvaluation?.map((stat: evaluationObject) => (
						<EvaluationListItem
							key={stat._id}
							evalObject={stat}
							isChecked={stat.chunkFeedback.adminHasCheckedWer === true}
							onCheckboxChange={e => handleCheckboxChange(stat, e.target.checked)}
						/>
					))}
				</Tbody>
			</Table>
		</Box>
	);
};

export default WerEvaluation;
