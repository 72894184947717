import Locale from '../../../utils/translations';
import React, { useEffect } from 'react';
import {
	Modal,
	ModalOverlay,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalBody,
	Flex,
	Input,
	Divider,
	Button,
	Spinner,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { BonfireAPI, ApiType } from '../../../models/BonfireApi';
import config from '../../../utils/config';

const AddGroundTruthModal = (props: { session: string; doOpen: boolean }) => {
	const { session, doOpen } = props;

	const { onOpen, onClose, isOpen } = useDisclosure();
	const [isCreating, setIsCreating] = React.useState<boolean>(false);

	const [gtFile, setGtFile] = React.useState<any>();
	const [error, setError] = React.useState<string>('');

	useEffect(() => {
		if (doOpen) {
			openNewSimulationModal();
		}
	}, [doOpen]);

	const addGT = async () => {
		const url = `${config.qa_api.url}/simulations/addGroundTruth`;
		const api = new BonfireAPI(ApiType.QaApi);

		if (!gtFile) {
			setError('Please select a file.');
			return;
		}

		setError('');

		const formData = new FormData();

		formData.append('gtFile', gtFile);
		formData.append('sessionId', session);

		setIsCreating(true);
		try {
			const response = await api.postWithConfig(url, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
			if (response.response.data.error) {
				setError(response.response.data.error);
				setIsCreating(false);
				return;
			}
		} catch (processError) {
			console.error(processError);
		}
		//await props.loadData();
		setIsCreating(false);
		onClose();
	};

	const openNewSimulationModal = () => {
		onOpen();
	};

	return (
		<Modal isOpen={isOpen} onClose={() => {}}>
			<ModalOverlay />
			<ModalCloseButton />

			<ModalContent bg="#F0F3F6">
				<ModalHeader>
					<Text variant="beTextHeading" fontSize="18px">
						{Locale.get('Add ground truth')}
					</Text>
				</ModalHeader>
				<ModalBody>
					<Flex flexDirection={'column'} justifyContent={'stretch'}>
						<Flex flexDirection={'column'}>
							<Text mb={3} fontSize={14} fontWeight={'bold'}>
								{Locale.get('Ground truth file (.txt)')}
							</Text>
							<Input
								type="file"
								variant="beVariant"
								borderColor="black"
								fontWeight={500}
								borderWidth={0}
								backgroundColor={'#ffffff00'}
								onChange={e => {
									const file = e.target.files?.[0];
									if (file) {
										setGtFile(file);
									}
								}}
							/>
						</Flex>

						<Divider mt="24px" mb="12px" />

						{error && (
							<Text color="red" fontSize="14px" mb={5}>
								{error}
							</Text>
						)}

						{!isCreating && (
							<Flex flexDirection={'row'} justifyContent={'space-between'}>
								<Button
									variant="bePrimary"
									onClick={addGT}
									textAlign="center"
									w="full"
								>
									{Locale.get('Add')}
								</Button>

								<Button onClick={onClose} textAlign="center" w="full">
									{Locale.get('Cancel')}
								</Button>
							</Flex>
						)}
						{isCreating && (
							<Flex
								flexDirection={'row'}
								justifyContent={'center'}
								alignItems={'center'}
							>
								<Spinner />
							</Flex>
						)}
					</Flex>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default AddGroundTruthModal;
