import { BonfireAPI, ApiType } from '../../../models/BonfireApi';
import Locale from '../../../utils/translations';
import React, { useEffect } from 'react';
import {
	Modal,
	ModalOverlay,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalBody,
	Flex,
	FormControl,
	Input,
	Divider,
	Button,
	Spinner,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import config from '../../../utils/config';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';

const CreateSimulationModal = (props: { doOpen: boolean; originalSession: any; loadData: any }) => {
	const { doOpen, originalSession } = props;

	const { onOpen, onClose, isOpen } = useDisclosure();
	const [newSimulation, setNewSimulation] = React.useState<any>();
	const [isCreating, setIsCreating] = React.useState<boolean>(false);

	const [audioFile, setAudioFile] = React.useState<any>();
	const [error, setError] = React.useState<string>('');

	useEffect(() => {
		if (doOpen) {
			openNewSimulationModal();
		}
	}, [doOpen]);

	const addNewSimulation = async () => {
		newSimulation.parentSessionId = originalSession.sessionId;
		const url = `${config.qa_api.url}/simulations/addSimulation`;
		const api = new BonfireAPI(ApiType.QaApi);

		setError('');

		const formData = new FormData();
		if (audioFile) {
			formData.append('audioFile', audioFile);
		}

		formData.append('simulation', JSON.stringify(newSimulation));

		setIsCreating(true);
		try {
			const response = await api.postWithConfig(url, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
			if (response.response.data.error) {
				setError(response.response.data.error);
				setIsCreating(false);
				return;
			}
		} catch (processError) {
			console.error(processError);
		}
		await props.loadData();
		setIsCreating(false);
		onClose();
	};

	const onTagChange = (key: string, value: string) => {
		const session = { ...newSimulation };
		session.tags = session.tags.map((tag: any) => {
			if (tag.key === key) {
				tag.value = value;
			}
			return tag;
		});
		setNewSimulation(session);
	};

	const openNewSimulationModal = () => {
		const simulation = { ...originalSession };
		simulation.name = '';
		simulation.sessionId = '-';
		const index = simulation.tags.findIndex((item: any) => item.key === 'sessionId');
		if (index !== -1) {
			simulation.tags.splice(index, 1);
		}
		simulation.tags.push({
			key: 'replayUrl',
			value: `${originalSession.aiCoreUrl}/captions/transcript?sessionId=${originalSession.sessionId}`,
		});

		setNewSimulation(simulation);
		onOpen();
	};

	return (
		<Modal isOpen={isOpen} onClose={() => {}}>
			<ModalOverlay />
			<ModalCloseButton />

			<ModalContent bg="#F0F3F6">
				<ModalHeader>
					<Text variant="beTextHeading" fontSize="18px">
						{Locale.get('Create new simulation')}
					</Text>
				</ModalHeader>
				<ModalBody>
					<Flex flexDirection={'column'} justifyContent={'stretch'}>
						<FormControl>
							<Input
								justifyContent={'stretch'}
								variant="beVariant"
								placeholder={Locale.get('Simulation name')}
								value={newSimulation?.name}
								borderColor="black"
								fontWeight={500}
								onChange={e => {
									setNewSimulation({
										...newSimulation,
										name: e.target.value,
									});
								}}
							/>
						</FormControl>
						<Divider mt="7px" mb="15px" />

						<Tabs>
							<TabList>
								<Tab>{Locale.get('Tags')}</Tab>
								<Tab>{Locale.get('Attachments')}</Tab>
							</TabList>

							<TabPanels>
								<TabPanel>
									{newSimulation &&
										newSimulation.tags.map((tag: any) => {
											return (
												<Flex key={tag.key} flexDirection={'column'}>
													<Text mt={3} fontSize={11}>
														{tag.key}
													</Text>
													<Input
														variant="beVariant"
														placeholder={tag.value}
														value={tag.value}
														borderColor="black"
														fontWeight={500}
														onChange={e => {
															onTagChange(tag.key, e.target.value);
														}}
													/>
												</Flex>
											);
										})}
								</TabPanel>
								<TabPanel>
									<Flex flexDirection={'column'}>
										<Text mb={3} fontSize={14} fontWeight={'bold'}>
											{Locale.get('Audio file (.mp3, .wav)')}
										</Text>
										<Input
											type="file"
											variant="beVariant"
											borderColor="black"
											fontWeight={500}
											borderWidth={0}
											backgroundColor={'#ffffff00'}
											onChange={e => {
												const file = e.target.files?.[0];
												if (file) {
													setAudioFile(file);
												}
											}}
										/>
									</Flex>
								</TabPanel>
							</TabPanels>
						</Tabs>

						<Divider mt="24px" mb="12px" />

						{error && (
							<Text color="red" fontSize="14px" mb={5}>
								{error}
							</Text>
						)}

						{!isCreating && (
							<Flex flexDirection={'row'} justifyContent={'space-between'}>
								<Button
									variant="bePrimary"
									onClick={addNewSimulation}
									textAlign="center"
									w="full"
								>
									{Locale.get('Create new')}
								</Button>

								<Button onClick={onClose} textAlign="center" w="full">
									{Locale.get('Cancel')}
								</Button>
							</Flex>
						)}
						{isCreating && (
							<Flex
								flexDirection={'row'}
								justifyContent={'center'}
								alignItems={'center'}
							>
								<Spinner />
							</Flex>
						)}
					</Flex>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default CreateSimulationModal;
