import React from 'react';
import { Flex, Text, Spacer, Table, Tbody, Tr, Td, Box } from '@chakra-ui/react';
import Locale from '../../../utils/translations';
import { BonfireAPI, ApiType } from '../../../models/BonfireApi';
import { LanguageOptionsModel, WerModel } from '../../../utils/interfaces';

const StatisticsListItem = ({
	stats,
	getLanguageFromCode,
}: {
	stats: WerModel;
	getLanguageFromCode: (code: string) => string;
}) => {
	const getPercentage = (value: number) => {
		return value.toFixed(2);
	};
	return (
		<Box
			bg="white"
			borderRadius="1px"
			fontWeight="600"
			fontSize="14px"
			p="10px"
			w="100%"
			m={'10px'}
		>
			<Flex flexDirection={'column'} mt={'10px'}>
				<Flex flexDirection={'column'} mt={'10px'}>
					<Text variant="beTextDescription">
						Language: {getLanguageFromCode(stats.language)}
					</Text>
					<Text variant="beTextDescription">
						Average word error rate: {getPercentage(stats.averageWer)}
					</Text>
					<Text variant="beTextDescription">
						Total word count: {stats.totalWordCount}
					</Text>
				</Flex>
			</Flex>
		</Box>
	);
};
const Statistics: React.FC = () => {
	const [languagesList, setLanguagesList] = React.useState<
		{ label: string; value: string }[] | []
	>([]);
	const [stats, setStats] = React.useState<WerModel[]>([]);

	const loadStats = async () => {
		try {
			const api = new BonfireAPI(ApiType.QaApi);
			const data = await api.get('s2sflow/getAllStats');

			setStats(data);
		} catch (error) {
			console.error(error);
		}
	};

	const loadLanguagesList = async () => {
		try {
			const api = new BonfireAPI(ApiType.BonfireCore);
			const data = await api.get('languages/get');

			const languagesOptions = data.map((language: LanguageOptionsModel) => {
				return { label: language.name, value: language.code };
			});

			setLanguagesList(languagesOptions);
		} catch (error) {
			console.error(error);
		}
	};

	const getLanguageFromCode = (code: string): string => {
		const language = languagesList.find(
			(lang: { value: string; label: string }) => lang.value === code,
		);
		return language ? language.label : code;
	};

	React.useEffect(() => {
		loadLanguagesList();
		loadStats();
	}, []);

	return (
		<Box display={'flex'} flexWrap={'wrap'} justifyContent={'center'}>
			<Flex alignItems="center" justifyContent="space-between" w={'60%'}>
				<Text fontSize="18px" fontWeight="700">
					{Locale.get('Statistics')}
				</Text>
				<Spacer />
			</Flex>
			<Table
				mt="23px"
				variant="unstyled"
				style={{ borderCollapse: 'separate', borderSpacing: '0 0.5em' }}
				w={'60%'}
			>
				<Tbody>
					{stats.length === 0 && (
						<Tr>
							<Td>{Locale.get('Loading...')}</Td>
						</Tr>
					)}
					{stats?.map((stat: WerModel, index: number) => (
						<StatisticsListItem
							getLanguageFromCode={getLanguageFromCode}
							stats={stat}
							key={index}
						/>
					))}
				</Tbody>
			</Table>
		</Box>
	);
};

export default Statistics;
