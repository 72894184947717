import React, { useEffect, useMemo } from 'react';
import { Text, Button, Input, Flex, Select, Checkbox } from '@chakra-ui/react';
import Locale from '../../../utils/translations';
import { BonfireAPI, ApiType } from '../../../models/BonfireApi';
import { LanguageOptionsModel } from '../../../utils/interfaces';
import useUser from '../../hooks/useUser';
import SessionCard from './SessionCard';
import { SessionModel } from '../../../utils/interfaces';
import SessionDatePicker from './SessionDatePicker';
import moment from 'moment';
import { getYesterdaysDate } from './helpers';

const SpeechToSpeechFlow: React.FC = () => {
	const [findSessionId, setFindSessionId] = React.useState<string>('');
	const [sessions, setSessions] = React.useState<SessionModel[]>([]);
	const [selectedLanguage, setSelectedLanguage] = React.useState<string>('');
	const [appliedSessionId, setAppliedSessionId] = React.useState<string>('');
	const [processedSessions, setProcessedSessions] = React.useState<boolean>(false);
	const today = new Date();
	const [dateRange, setDateRange] = React.useState<[Date | null, Date | null]>([
		getYesterdaysDate(),
		today,
	]);
	const [isCalendarOpen, setIsCalendarOpen] = React.useState(false);
	const [localStartDate, localEndDate] = dateRange;
	const { isExternal, userData } = useUser();
	const [languagesList, setLanguagesList] = React.useState<
		{ label: string; value: string }[] | []
	>([]);
	const renderSession = (sessionId: string) => {
		document.location.href = `/s2s-flow/${sessionId}`;
	};

	const renderSessionChunks = (sessionId: string) => {
		document.location.href = `/s2s-flow/chunks/${sessionId}`;
	};

	const renderSessionSimulations = (sessionId: string) => {
		document.location.href = `/s2s-flow/simulations/${sessionId}`;
	};
	const handleDateChange = (dates: [Date | null, Date | null]) => {
		const [start, end] = dates;
		setDateRange([start, end]);
	};

	const getDateFormat = (inputDate: Date | null | undefined) => {
		if (!inputDate) {
			return moment(new Date()).format('YYYY-MM-DD');
		}
		return moment(inputDate).format('YYYY-MM-DD');
	};

	const getSessions = async () => {
		try {
			const api = new BonfireAPI(ApiType.QaApi);
			let url = `s2sflow/getSessions?isProcessed=${processedSessions ? 'true' : 'false'}`;

			if (processedSessions) {
				if (localStartDate) {
					url += `&startDate=${getDateFormat(localStartDate)}`;
				}
				if (localEndDate) {
					url += `&endDate=${getDateFormat(localEndDate)}`;
				}
			}

			const sessionsData = await api.get(url);
			setSessions(sessionsData);
		} catch (error) {
			console.error(error);
		}
	};

	const loadLanguagesList = async () => {
		try {
			const api = new BonfireAPI(ApiType.BonfireCore);
			const data = await api.get('languages/get');

			const languagesOptions = data.map((language: LanguageOptionsModel) => {
				return { label: language.name, value: language.code };
			});

			setLanguagesList(languagesOptions);
		} catch (error) {
			console.error(error);
		}
	};

	const onInputFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedLanguage(e.target.value);
	};

	const filteredSessions = useMemo(() => {
		let filtered = sessions;

		if (isExternal) {
			filtered = filtered.filter(session => session.client === 'ondemand');
		}

		if (selectedLanguage) {
			filtered = filtered.filter(
				session =>
					session.distinctLanguages.includes('en') &&
					session.distinctLanguages.includes(selectedLanguage),
			);
		}

		if (appliedSessionId) {
			filtered = filtered.filter(session => session.sessionId === appliedSessionId);
		}

		return filtered;
	}, [sessions, selectedLanguage, isExternal, appliedSessionId]);

	useEffect(() => {
		loadLanguagesList();
		getSessions();
	}, [processedSessions, dateRange]);

	return (
		<Flex flexDirection={'column'} width={'100%'}>
			<Flex flexDirection="row" justifyContent={'left'} alignItems={'center'}>
				<Input
					pr={2}
					width={'30%'}
					shadow="0px 4px 4px 0px rgba(74, 74, 74, 0.04)"
					fontWeight="400"
					variant={'beVariant'}
					placeholder={'Session ID'}
					_placeholder={{ fontWeight: 'normal' }}
					value={findSessionId}
					onChange={e => setFindSessionId(e.target.value)}
				/>
				<Button
					ml={2}
					size="sm"
					variant={'bePrimary'}
					onClick={() => setAppliedSessionId(findSessionId)}
				>
					{Locale.get('Search')}
				</Button>
				{isExternal && (
					<>
						<Text variant="beTextDescription" ml={5}>
							Filter by language:
						</Text>
						<Select
							bgColor={'#fff'}
							borderRadius={'4px'}
							mx={2}
							size={'sm'}
							width={'10%'}
							placeholder="Language"
							value={selectedLanguage}
							onChange={onInputFilterChange}
						>
							{languagesList.map(
								(option: { value: string; label: string }, index: number) => (
									<option key={index} value={option.value}>
										{option.label}
									</option>
								),
							)}
						</Select>
					</>
				)}
				{!isExternal && (
					<>
						<Flex
							ml={5}
							alignItems={'center'}
							justifyContent={'center'}
							flexDirection={'row'}
						>
							<Text fontSize="14px" fontWeight="500" margin={'auto'}>
								Processed
							</Text>
							<Checkbox
								ml={2}
								iconColor="white"
								onChange={() => setProcessedSessions(!processedSessions)}
							/>
						</Flex>
						{processedSessions && (
							<Flex
								ml={5}
								alignItems={'center'}
								justifyContent={'center'}
								flexDirection={'row'}
							>
								<SessionDatePicker
									startDate={localStartDate}
									endDate={localEndDate}
									calendarOpen={isCalendarOpen}
									onHandleChange={handleDateChange}
									// eslint-disable-next-line @typescript-eslint/no-empty-function
									onHandleChangeRaw={() => {}}
									onApplyButtonClick={() => setIsCalendarOpen(false)}
									onCalendarinputClick={() => setIsCalendarOpen(true)}
								/>
							</Flex>
						)}
					</>
				)}
			</Flex>
			<Text mt={5} fontSize={30} fontWeight={'bold'}>
				Sessions
			</Text>
			<Flex width={'100%'} mt={5} flexDirection={'column'}>
				{filteredSessions?.length > 0 &&
					filteredSessions.map(session => (
						<SessionCard
							session={session}
							key={session.sessionId}
							renderSession={renderSession}
							renderSessionChunks={renderSessionChunks}
							renderSessionSimulations={renderSessionSimulations}
							isExternal={isExternal}
							isSessionLocked={
								session.processStatus === 'locked' &&
								session.processedBy !== userData.email
							}
						/>
					))}
				{filteredSessions?.length === 0 && (
					<Flex width={'100%'} alignItems={'center'} justifyContent={'center'} mt={5}>
						<Text ml={5} fontSize={18} fontWeight={'bold'}>
							No sessions found
						</Text>
					</Flex>
				)}
			</Flex>
		</Flex>
	);
};

export default SpeechToSpeechFlow;
