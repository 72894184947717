import React from 'react';
import { Box, Text, Flex, Button } from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';

export default function SessionDatePicker({
	startDate,
	endDate,
	calendarOpen,
	onHandleChange,
	onHandleChangeRaw,
	onApplyButtonClick,
	onCalendarinputClick,
}: {
	startDate: Date | null;
	endDate: Date | null;
	calendarOpen: boolean;
	onHandleChange: (range: [Date | null, Date | null]) => void;
	onHandleChangeRaw: (event: any) => void;
	onApplyButtonClick: () => void;
	onCalendarinputClick: () => void;
}) {
	return (
		<Box p="10px">
			<Flex justifyContent="right">
				<Text fontSize="14px" fontWeight="500" margin={'auto'}>
					Select Date Range
				</Text>
				<Box display={'flex'} alignContent={'center'} padding={'5px'}>
					<DatePicker
						onChange={onHandleChange}
						onChangeRaw={onHandleChangeRaw}
						onInputClick={onCalendarinputClick}
						startDate={startDate}
						endDate={endDate}
						selectsRange
						maxDate={new Date()}
						isClearable={false}
						className="date-picker-input-field"
						placeholderText="Click to select a date range"
						open={calendarOpen}
					>
						<Button
							display={'flex'}
							margin={'auto'}
							size="sm"
							borderWidth="1px"
							borderRadius="sm"
							borderColor="transparent"
							bgColor={'#1E599E'}
							color={'white'}
							onClick={onApplyButtonClick}
						>
							Apply
						</Button>
					</DatePicker>
				</Box>
			</Flex>
		</Box>
	);
}
